.feature-walkthrough-module {
    position: relative;

    &__off {
        opacity: 0.25;
    }

    &__content {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        flex: 1;
    }

    &__text {
        margin-top: 2em;
        display: none;

        &--active {
            display: block;
        }
    }

    &__arrows {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
    }
    &__arrow {
        z-index: 1;
        width: 2em;
        height: 2em;
        display: block;
    }

    &__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 1024px) {
            flex-direction: row;
            align-items: unset;
        }
    }

    &__title {
        margin-bottom: 1em;
        font-weight: 600;
        display: flex;
        flex: 1;
    }

    &__image {
        width: 100%;
        position: relative;
        flex: 1;
    }

    &__tab-bar {
        display: flex;
        margin: 2em 0 2em auto;
        flex-direction: row;
    }
}
