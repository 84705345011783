

.carousel-item-skeleon {
    width: 110px;
    height: 120px;
    padding: 17px 11px;
    margin: 4px;
    box-sizing: border-box;
    background-color: var(--fog);
}

.carousel-item {
    @extend .carousel-item-skeleon;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: black;
    cursor: pointer;

    &--selected {
        color: #FFFFFF;
        background-color: #000000;
    }

    &__period-information {
        font-size: 12px;
    }

    &__installment-info {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-weight: bold;

        & > p {
            font-size: 16px;
        }
    }
}

.carousel {
    display: flex;
    max-width: 632px;
    align-items: center;
    --transformX: -64px;

    &__arrow {
        width: 25px;
        cursor: pointer;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }
    }

    &__container {
        width: 586px;
        overflow: hidden;
    }

    &__elements {
        display: flex;
        width: fit-content;
        transform: translateX(calc(var(--transformX) * 1px));
        transition: all 0.5s ease-out;
    }
}
