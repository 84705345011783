@import "/assets/styles/utils.scss";

.splash {
    margin-top: 2px;
    padding: em(32) em(48);
    background-color: var(--pine);
    color: var(--primary-white);
    font-size: 14px;

    button {
        text-decoration: underline;
    }
}
