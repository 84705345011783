.feature-walkthrough-item {
    display: none;
    position: relative;
    max-height: calc(100vh - var(--header-height));

    &--is-active {
        display: block;
    }

    &__media {
        width: calc(2 / 3) * 100%;
        margin: auto;
        max-width: 340px;

        @media (min-width: 768px) and (max-height: 1024px) {
            max-width: 280px;
        }
    }

    &__video {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        transition: opacity .25s .15s;
    }
}
