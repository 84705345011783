@use "sass:math";

.currency-table-module {
    max-width: 940px;
    margin: 0 auto;

    &__title {
        margin-bottom: 1em;
    }

    &__table {
        width: 100%;

        text-align: left;
        font-size: .85em;
        border-spacing: 0;

        th {
            font-weight: bold;
        }

        th, td {
            padding: .85rem 1rem;
            background-color: #F2F2F2;;
        }

        tbody tr:nth-child(odd) td  {
            background-color: white;
        }
    }
}
