.angle {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;

    &__svg {
        display: block;
    }
}
