.fullscreen-media {
    pointer-events: none;
    &__image, &__image img {
        position: absolute;
        z-index: 0;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__video {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: opacity 0.25s 0.15s;
        overflow: hidden;

        &> iframe {
            position: absolute;
            width: 100%;
            height: var(--aspect-ratio-height);
            min-height: 100%;
            min-width: var(--aspect-ratio-width);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
