@use "sass:math";
@import "/assets/styles/utils.scss";
@import "/assets/styles/typography.scss";

.rich-text-module {
    max-width: math.div(6, 12) * 85em;
    margin-left: auto;
    margin-right: auto;

    h2 {
        @extend .heading-300;
    }
    h3 {
        @extend .heading-200;
    }
    h4 {
        @extend .heading-100;
    }

    p:after {
        content:"";
        display:inline-block;
        width:0px;
    }
}
