@import "/assets/styles/utils.scss";

.info-modal {
    display: block;

    &__popup {
        position: fixed;
        z-index: 1000;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: black;
        animation: fadein 0.3s;
        animation-fill-mode: forward;
        &--closed {
            display: none;
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &__logo {
        position: absolute;
        top: em(22);
        left: em(16);

        &__svg {
            height: em(28);
        }
    }


    &__close {
        position: absolute;
        top: 1em;
        right: 1em;
    }

    &__modal-content {
        margin: 0 auto;
        width: fit-content;
        padding: 16px;
        border-radius: em(24, 14);

        & > * > * > h1 {
            font-size: 2.5em;
            font-weight: bold;
        }
        & > * > * > h2 {
            font-size: 2em;
            font-weight: bold;
        }
        & > * > * > h3 {
            font-size: 1.5em;
            font-weight: bold;
        }

        &-wrapper {
            max-width: 45em;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    &__title {
        margin-bottom: em(16, 32);
        text-align: center;
    }
}
