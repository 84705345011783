@import "/assets/styles/utils.scss";

.qa-list-module {
    margin-bottom: 2em;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        @media (min-width: 1024px) {
            text-align: left;
            align-items: start;
            flex-direction: row;
            gap: em(40);
            padding: 0 2em;
        }
    }

    &__left-container {
        margin-bottom: 40px;
        @media  (min-width: 768px) {
            max-width: 570px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__title {
        margin-bottom: 24px;

        @media  (min-width: 1024px) {
            text-align: left;
            padding: 0 0.5em 0 0;
        }
    }
    &__body-text {
        margin-bottom: 24px;
    }
    &__accordion {
        width: 100%;
        max-width: 1130px;
    }
}
