.progress-bar{
    margin-left: -.25em;
    margin-right: -.25em;
    border-radius: 2em;

    &__item {
        border-radius: 1em;
        margin: 0 .25em;
        position: relative;
        background: var(--fog);
        width: 6px;
        height: 6px;
        &:global(.active) {
            width: 36px;
        }


        &::after {
            display: block;
            content: "";
            position: absolute;
            background: transparent;
            cursor: pointer;
            left:0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 2em;
        }

        &__inner {
            transform-origin: 0 center;
            height: 100%;
            border-radius: 1em;
            height: 6px; // ems give different height?!

            &--done {
                background: #000000;
            }

            &--active {
                background: #000000;
                animation: barin .5s both linear;
            }
        }
    }
}

@keyframes barin {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
