@import "/assets/styles/utils.scss";

.full-screen-image-module {
    position: relative;
    overflow: hidden;
    height: calc(100vh - var(--header-height));

    &__content {
        position: relative;
        display: flex;
        height: 100%;
        color: var(--primary-white);
        mix-blend-mode: difference;
        z-index: 1;

        &--title-bottom {
            align-items: flex-end;
        }

        &> div {
            width: 100%;
        }
    }

    &__content-inner {
        &--angle-top {
            margin-top: 9%;
        }

        &--angle-bottom {
            margin-bottom: 9%;
        }
    }

    &__title {
        margin-top: em(40, 80);
        margin-bottom: em(40, 80);
        text-transform: uppercase;

        @media only screen and (min-width: 1024px) {
            margin-top: em(40, 144);
            margin-bottom: em(40, 144);
        }
    }

    &__angle {
        position: absolute;
        left: 0;
        right: 0;
        color: var(--primary-white);

        &--top {
            top: 0;
        }

        &--bottom {
            bottom: 0;
        }
    }
}
