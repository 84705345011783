@use "sass:math";
$browser-context: 16;

@function em($pixels, $context: $browser-context) {
    @return #{math.div($pixels, $context)} + em;
}

@mixin button-hover {
    @media (hover: hover) {
        --angle-translate: 1;
    }
}
