@import "/assets/styles/utils.scss";

.hero-basic-module {
    &__inner {
        padding: 40px;
        min-height: 540px;
    }

    &__title {
        max-width: 8em;
        padding-bottom: .3em;
        hyphens: auto;
    }

    &__subtitle {
        max-width: 30em;
        padding-bottom: .1em;
        hyphens: auto;
    }

    &__cta-button {
        margin-top: 2em;

        &--hover {
            @include button-hover;
        }
    }
}
