@import "/assets/styles/utils.scss";

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2em;
    flex: 1;
    background-color: var(--fog);
    transition: all .15s;
    position: relative;
    border-radius: 8px;
    --image-opacity: 0;
    --cta-arrow-translate: 0;

    &:hover {
        --cta-arrow-translate: 4px;
    }

    &--link{
        cursor: pointer;

        @media (hover: hover) {
            &:hover,
            &:focus {
                background-color: var(--gray-60);
                --image-opacity: .25;
            }
        }
    }

    &__title {
        position: relative;
        margin-bottom: .5em;

        svg {
            display: none;
        }
    }

    &__text {
        position: relative;
    }

    &__image-wrapper {
        position: absolute;
        top:0;
        left:0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        z-index: 0;
        border-radius: inherit;
    }

    &__image {
        opacity: var(--image-opacity);
        filter: blur(1em);
        width: calc(100% + 4em);
        height: calc(100% + 4em);
        margin-top: -2em;
        margin-left: -2em;
        object-fit: cover;
        object-position: center;
    }

    &__cta {
        margin-top: em(32, 14);

        svg {
            transform: translateX(var(--cta-arrow-translate));
        }
    }

    &__label {
        position: absolute;
        top:0;
        left: em(32,14);
        transform: translateY(-50%);
        padding: em(5,14) em(12,14);
        z-index: 1;
        color: var(--primary-white);
        border-radius: em(16,14);
        border-bottom-left-radius: 0;
    }

    @media (max-width: 767px) {
        &__text, &__cta {
            display: none;
        }
        &__title svg {
            display: inline-block;
            width: em(16, 14);
            padding-left: em(5, 14);
            vertical-align: middle;
            padding-bottom: 2px;
        }
    }
}
