.hero-parallax-module {
    position: relative;
    background-color: var(--primary-white);

    &--center-large {
        --title-bottom: 13em;

        @media (max-width: 500px) {
            --title-bottom: 6em;
        }
    }

    &--left-large {
        --title-bottom: 13em;

        @media (max-width: 500px) {
            --title-bottom: 4em;
        }
    }

    &--left-extra-large {
        --title-bottom: 8em;

        @media (max-width: 500px) {
            --title-bottom: 4em;
        }
    }

    &--center-extra-large {
        --title-bottom: 8em;

        @media (max-width: 500px) {
            --title-bottom: 5em;
        }
    }

    &__title {
        will-change: transform, opacity;

        &--centered {
            text-align: center;
        }

        &--black {
            color: black;
        }

        &--white {
            color: var(--primary-white);
        }

        &--inverted {
            color: var(--primary-white);
            mix-blend-mode: difference;
        }

        &--bottom {
            transform: translateY(calc(-100% - var(--title-bottom)));
            color: black;

            @media (max-width: 500px) {
                display: none;
            }
        }

        &--top {
            bottom: var(--title-bottom);
            position: absolute;
            left:0;
            z-index: 11;
            right:0;
        }
    }

    &__wrapper {
        position: relative;
        height: 100%;
        overflow: hidden;
        z-index: 2;
        clip-path: polygon(0 0, 100% 0, 100% var(--bottom-angle-position), 0 100%);

        &--inverted {
            overflow: visible;
        }
    }

    &__inner {
        height: var(--height);
        overflow: hidden;
        position: relative;
        z-index: 2;

        &__background {
            height: 100%;
            width: 100%;
            display: block;
            object-fit: cover;
        }
    }

    &__bottom {
        position: relative;
        z-index: 0;

        &__angle {
            position: absolute;
            display: none;
            bottom: 100%;
        }
    }
}
