@use "sass:math";

.image-module {
    margin-left: auto;
    margin-right: auto;

    &__container--edge-to-edge {
        max-width: none;
    }

    &--maxwidth-2 {
        max-width: math.div(2, 12) * 1920px;
    }
    &--maxwidth-4 {
        max-width: math.div(4, 12) * 1920px;
    }
    &--maxwidth-6 {
        max-width: math.div(6, 12) * 1920px;
    }
    &--maxwidth-8 {
        max-width: math.div(8, 12) * 1920px;
    }
    &--maxwidth-10 {
        max-width: math.div(10, 12) * 1920px;
    }
    &--maxwidth-12 {
        // max-width: math.div(12, 12) * 1920px;
        max-width: none;
    }
}
