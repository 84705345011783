.product-list-module {
    &__title {
        margin-bottom: 1em;
        text-align: center;
    }

    &__cta {
        margin-top: 3em;
        text-align: center;
    }
}
