@import "/assets/styles/utils.scss";
.example-of-interest {
    padding: em(48);
    display: flex;
    flex-direction: column;
    background-color: var(--mint);
    color: var(--primary-black);

    &__title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    &__bullet {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        gap: .5em;
        height: 66px;

        border-bottom: 1px solid var(--pine);

        & > span {
            line-height: 14px;
        }

        & > strong {
            font-weight: bold;
        }

        & > span > span {
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
