@import "/assets/styles/utils.scss";

.panel {
    border-top: 1px solid var(--fog);
    transition: all .2s;
    background-color: transparent;

    &:last-child {
        border-bottom: 1px solid var(--fog);
    }

    :global(.segment-business) & {
        border-top: 1px solid var(--gray-60);
    }

    &--dark-theme {
        color: var(--primary-white);
        border-top: 1px solid rgba(255,255,255, 0.05);
        &:last-child {
            border-bottom: 1px solid rgba(255,255,255, 0.05);
        }
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        text-align: left;
        position: relative;
        padding: em(32) 0;
        transition: all .2s;

        @media (min-width: 768px) {
            padding: em(32);
        }

        &__icon {
            width: 34px;
            flex: 0 0 34px;
            height: 34px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            justify-self: flex-end;
            margin-left: .5em;
            margin-right: .5em;
            transform: rotate(0deg);
            transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            svg {
                color: white;
                width: em(16);
                min-width: em(16);
            }

            &--is-active {
                transform: rotate(-180deg);
                background-color: var(--gray-60);
                svg {
                    color: black;
                }
            }

        }
    }

    &__inner {
        overflow: hidden;
        will-change: height;
        transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &__content {
        margin-right: em(32);
        padding-bottom: em(32);

        @media (min-width: 768px) {
            margin: em(2) em(60) em(24) em(32);
        }

        max-width: em(642);
        opacity: 0;
        transition: opacity .3s;

        &--is-active {
            opacity: 1;
        }

        &--fill {
            max-width: none;
            margin-bottom: 0;
        }
    }
}
