@use "sass:math";
@import "/assets/styles/utils.scss";


.hero-blog-module {
    position: relative;
    overflow: hidden;

    &__content {
        $width: math.div(8, 12) * 85em;
        margin-top: 26em;
        padding: 3em 0;
        z-index: 1;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        max-width: $width;
        background-color: var(--primary-white);

        @media (max-width: ($width + 3em)) {
            margin-left: 1.5em;
            margin-right: 1.5em;
        }
        @media (max-width: 700px) {
            margin-left: auto;
            margin-right: auto;
        }

        &__inner {
            max-width: math.div(6, 12) * 85em;
            margin-left: auto;
            margin-right: auto;

        }

        @media (max-width: 1200px) {
            margin-top: 16em;

        }
        @media (max-width: 700px) {
            margin-top: 14em;
        }

        &__angle {
            position: absolute;
            bottom: 100%;
            left:0;
            width: 100%;
        }
    }

    &__date {
        color: var(--cloud);
        margin-bottom: 2em;
        display: block;
        text-transform: uppercase;
    }

    &__intro {
        color: var(--cloud);
        margin-top: 1.5em;
    }

    &__angle {
        position: absolute;
        bottom: 0;
        left:0;
        width: 100%;
    }
}
