@use "sass:math";

.key-numbers-module {
    text-align: center;

    &__list {
        padding: 3em 0;

        @media only screen and (min-width: 768px) {
            display: flex;
        }
    }

    &__item {
        margin: 0 1.5em 3em;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-top: 0.5em;
        }

        @media only screen and (min-width: 768px) {
            margin: 0 2em;
            width: math.div(100, 3) * 1%;
        }
    }
}
