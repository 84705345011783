@import "/assets/styles/utils.scss";

.card-styles-module {
    position: relative;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__title {
        text-transform: uppercase;
    }

    &__card-outer {
        margin-top: 4em;
        width: 100%;
        overflow: hidden;
        height: var(--inner-height);

        @media (max-width: 850px) {
            margin-top: em(48);
        }
    }

    &__cards {
        display: flex;
        align-content: center;
        padding-bottom: 2em;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        width: 100%;

        &::before,
        &::after {
            display: none;
            content: "";
            height: 1px;

            @media (max-width:760px) {
                display: block;
                flex: 0 0 calc(50vw - 228px / 2);
            }
        }
    }

    &__card {
        flex: 0 0;
        flex-basis: em(268);
        scroll-snap-align: center;
        scroll-snap-stop: always;
        padding-left: em(24);
        padding-right: em(24);
        transform-origin: center center;
        box-sizing: padding-box;
        will-change: transform;

        &:first-child {
            margin-left: auto;
        }

        &:last-child {
            margin-right: auto;
        }

        &:nth-child(2) {
            z-index: 1;
            position: relative;
        }

        @media (max-width: 850px) {
            flex-basis: em(228);
            padding-left: .75em;
            padding-right: .75em;
        }
    }

    &__card-title {
        margin-top: 1em;
        transition: all .25s;
        font-size: em(24);

        @media (max-width: 850px) {
            opacity: 1 !important;
        }
    }

    &__button {
        margin-top: 4em;
    }
}
