@use "sass:math";
@import "/assets/styles/utils.scss";

.campaign-module {
    $self: &;
    color: white;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }
    @media (max-width: 768px) {
        padding: 40px 0;
        flex-direction: column-reverse;
    }

    &--border {
        position: relative;
        #{$self}__box {
            border-bottom: 1px solid var(--gray-60);
        }
        @media (min-width: 768px) {
            :first-child #{$self}__box {
                border-right: 1px solid var(--gray-60);
            }
        }
    }

    // Format - whether we force the boxes square, or naturally expand to follow content
    @media (min-width: 768px) {
        #{$self}__box {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;

            &-wrapper {
                padding-bottom: 45%;
                // height: calc(100vh - var(--header-height));
                // height: 90vh;
                // min-height: 600px;
                position: relative;
                width: 100%;
            }
        }
    }

    &__title {
        margin-bottom: 30px;
    }
    &__rolling-text {
        margin-top: auto;
        text-align: right;
        width: 100%;

        &__viewport {
            position: relative;
            overflow: hidden;
        }
        &__scroller {
            opacity: .5;
            transition: all .6s;
        }
    }

    &__rolling-active {
        height: 1.3em;
        line-height: 130%;
        text-align: right;
        margin-bottom: 30px;
        text-align: right;
        @media (min-width: 768px) {
            margin-top: 30px;
            margin-bottom: 0;
        }
    }

    &__cta {
        margin-top: 30px;
        align-self: flex-end;
    }

    &__media-wrapper {
        @media (min-width: 768px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        @media (max-width: 768px) {
            aspect-ratio: 1;
            position: relative;
        }
    }
    &__media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform-origin: bottom left;
        transition: all .6s ease-out;
        transition-delay: .6s;
        transform: scale(0);
        display: flex;
        flex-direction: column;
        line-height: 0;

        :global(.video-wrapper), > :global([class*="Image_image"])  {
            position: absolute !important;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            img, video {
                object-fit: cover;
            }
        }

        &:global(.animate-in) {
            z-index: 10;
            transition-delay: 0s;
            transform: scale(1);
        }
    }

    &__overlay-media {
        max-width: 100%;
        align-self: center;
        position: relative;
        z-index: 10;
        margin-top: auto;
        padding: 16px;

        @media (min-width: 768px) {
            max-width: 40%;
            padding: 40px;
            &--position-bottom-left {
                align-self: flex-start;
            }
            &--position-bottom-right {
                align-self: flex-end;
            }
        }

        img {
            // position: absolute;
            // left: 0;
            // right: 0;
            // bottom: 0;
            max-width: 100%;
        }
    }

    &__box {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px 16px;
        overflow: hidden;
        position: relative;

        @media (min-width: 768px) {
            padding: 40px;
        }

        &-wrapper__media-position-background-fill {
            @media (max-width: 768px) {
                padding-bottom: 100%;
                position: relative;
                width: 100%;

                #{$self}__box {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        &--narrow-text {
            @media (min-width: 768px) {
                // padding-right: 190px;
                max-width: 570px;
                margin-left: auto;
                margin-right: auto;
                justify-content: center;
            }
        }

        &__sub-title {
            color: var(--gray-90);
            margin-bottom: 16px;
        }
        &__title, &__body-text {
            margin-bottom: 32px;
            @media (min-width: 768px) {
                margin-bottom: 40px;
            }

            &:global(.paragraph-100), &:global(.heading-200) {
                margin-bottom: 20px;
            }
        }
        &__cta-group {
            @media (max-width: 767px) {
                margin-bottom: 32px;
                span {
                        flex-grow: 1;
                }
            }
        }
        &__cta {

            @media (max-width: 767px) {
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                justify-content: center;
            }

        }

        &__usp {

            @media (max-width: 767px) {
                display: flex;
            }
            @media (min-width: 768px) {
                margin-top: auto;
            }


            &__rolling-text-container {
                display: inline-flex;
                flex-grow: 1;
            }

            li {
                display: flex;
                font-size: 14px;
                margin-bottom: 16px;
            }

            svg {
                width: 24px;
                margin-right: 16px;
            }
        }


        &__media-wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            align-self: center;
            &-position-background-fill {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                :global([class*="BasicImage"]) {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    transform: translate(-50%, -50%);
                    // right: 0;
                    // bottom: 0;
                    // height: 100%;
                }
            }
            &-position-center {
                max-width: 80%;
                width: 100%;
                max-height: 620px;
                // max-width: 75%;
                margin-top: auto;
                // padding: 40px 40px 0 40px;
                margin-bottom: auto;
                align-self: center;
                display: flex;
                justify-content: center;
                flex-grow: 1;
                @media (max-width: 767px) {
                    max-height: 100%;
                }
                :global([class*="Image_image"]), :global([class*="BasicImage"]) {
                    position: relative;
                    object-fit: contain;
                    max-height: 100%;
                    max-width: 100%;
                }

            }
            &-position-bottom {
                max-width: 60%;
                // max-height: 55%;
                padding: 40px 40px 0 40px;
                margin-bottom: -40px;
                margin-top: auto;
                align-self: center;
                width: 100%;
                :global([class*="BasicImage"]) {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            &-animation-notification {
                opacity: 0;
                // max-width: 400px;
            }
        }
        &__media {
        }
    }

    &--in-view {
        #{$self}__box__media-wrapper-animation-notification {
            animation: notification 0.5s forwards ease;
            animation-delay: 0.5s;
        }
    }
}

@keyframes notification {
    0% {
        transform: scale3d(0.1, 0.1, 1);
        opacity: 0;
    }

    100% {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}
