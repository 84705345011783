@import "/assets/styles/utils.scss";

.representative-example {
    padding: em(48, 16);
    background-color: var(--fog);

    font-size: 14px;

    & h1, h2, h3 {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }

    &__bullet {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        & > strong {
            font-weight: bold;
        }

        &:nth-last-of-type(1) {
            margin-bottom: 0px;
        }
    }
}
