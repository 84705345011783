@import "/assets/styles/grid.scss";

.loan-module {
    &__container{
        display: flex;
        text-align: center;
        justify-content: space-around;
        gap: grid-gap();
        flex-direction: column;

        @media (min-width: 1080px) {
            flex-direction: row;
        }
    }

    &__section{
        @media (min-width: 1080px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }


    &__slider {
        width: 80%;
        max-width: 326px;
        text-align: left;
        padding-bottom: 54px;
        margin: 18px 25px;

        @media (min-width: 1080px) {
            width: 100%;
            max-width: 582px;
            margin: 35px 25px;
            padding-bottom: 92px;
        }
    }

    &__divider {
        display: none;
        width: 1px;
        max-width: 1px;
        min-height: 920px;
        background-color: #E5E5E5;

        @media (min-width: 1080px) {
            display: block;
        }
    }

    &__aside {
        max-width: 378px;
        text-align: left;
        margin: 0 auto;

        @media (min-width: 1080px) {
            margin: 0;
        }
    }

    &__title {
        margin: 0 25px 9px 25px;
        font-size: 16px;
        line-height: 25.6px;
        font-weight: bold;
    }
    &__main-title {
        margin: 0 0 48px 0;
        font-size: 40px;
        line-height: 42px;
        font-weight: bold;
        text-align: center;


        @media (min-width: 1080px) {
            margin: 0 0 96px 0;
            font-size: 64px;
            line-height: 67.2px;
        }
    }

    &__carousel {
        margin: 9px auto;

        @media (min-width: 1080px) {
            margin: 15px auto;
        }
    }

    &__modal-opener {
        display: block;
        text-decoration: underline;
        margin: 1.5em auto;
    }

    &__redirect {
        display: block;
        margin: 43px auto;

        @media (min-width: 1080px) {
            margin: 80px auto;
        }

        &--mobile {
            display: block;
            @media (min-width: 1080px) {
                display: none;
            }
        }

        &--desktop {
            display: none;
            @media (min-width: 1080px) {
                display: block;
            }
        }
    }




    &__main-info {
        margin: 0 25px;
        font-size: 40px;
        line-height: 42px;
        font-weight: bold;

        @media (min-width: 1080px) {
            font-size: 64px;
            line-height: 67.2px;
        }
    }
}
