@use "sass:math";

.hero-subscriptions-module {
    $self: &;

    &__hero {
        position: relative;
        overflow: hidden;
        margin-bottom: -24em;

        @media (min-width: 1024px) {
            margin-bottom: -32em;
        }
    }

    &__image {
        object-fit: cover;
        height: calc(100% + 4em);
        position: absolute;
        display: block;
        left: -2em;
        right: -2em;
        top: -2em;
        bottom: -2em;
        width: calc(100% + 4em);
        z-index: 0;
    }

    &__angle {
        position: absolute;
        bottom: 0;
        color: var(--primary-white);
    }

    &__title-wrapper {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-top: calc(var(--header-height) + 3em);
        margin-bottom: 24em;
        padding-bottom: 3em;

        @media (min-width: 1024px) {
            margin-top: calc(var(--header-height) + 6em);
            margin-bottom: 32em;
            padding-bottom: 4em;
        }
    }


    &__title {

        #{$self}--white & {
            color: var(--primary-white);
        }

        #{$self}--black & {
            color: var(--primary-black);
        }
    }

    &__subscriptions {


    }
}
