@use "sass:math";
@import "/assets/styles/utils.scss";

.product-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: em(20);
    grid-row-gap: em(20);

    @media (min-width: 700px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1300px) {
        grid-template-columns: repeat(4, 1fr);
    }


    &__item {
        display: flex;
        flex-direction: column;
    }
}
