@use 'sass:math';
@import '/assets/styles/utils.scss';

.headline-text-and-cta {
    $self: &;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    &__container:first-child {
        max-width: 1200px;
        margin: 0 auto;
    }

    &__content {
        @media (min-width: 768px) {
            // max-width: 680px;
            // margin-left: 12.5%;
        }
        @media (min-width: 1024px) {
            // margin-left: 140px;
        }
    }

    &--center {
        text-align: center;
        justify-content: center;
        #{$self}__content,
        #{$self}__title,
        #{$self}__business-signup,
        #{$self}__body {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__title {
        opacity: 1;
        &:not(:last-child) {
            margin-bottom: 32px;
            @media (min-width: 768px) {
                margin-bottom: 40px;
            }
        }
        @media (min-width: 1224px) {
            // max-width: 60%;
        }
        * {
            display: inline;
        }
        :global(p) {
            margin: 0;
        }
        svg {
            height: 0.75em;
            vertical-align: baseline;
            min-width: 0;
            width: auto;
            margin-left: 0.3em;
        }
    }

    &__body {
        max-width: 800px;
    }

    &__cta {
        margin-top: 32px;
        @media (min-width: 768px) {
            margin-top: 40px;
        }
    }

    &__app-badge img {
        margin-top: 2em;
        width: 150px;
    }

    @media (min-width: 768px) {
        &__app-badge {
            display: none !important;
        }
    }
    @media (max-width: 767px) {
        &__send-sms {
            display: none !important;
        }
    }

    &__send-sms {
        max-width: 600px;
        [class*='small-text'] {
            opacity: 1;
        }
        margin-top: 32px;
        @media (min-width: 768px) {
            margin-top: 40px;
        }
        :global(.button--pill) {
            // background-color: black;
        }
    }

    &__business-signup {
        max-width: 800px;
        text-align: left;
        margin-top: 3em;
        :global(h2) {
            font-size: 1.5em;
        }
    }
}
