@use 'sass:math';
@import '/assets/styles/utils.scss';

.subscriptions-list {
    padding-top: 34px;
    @media (max-width: 767px) {
        overflow-x: scroll;
        display: block;
        white-space: nowrap;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.subscriptions-carousel {
    @media (max-width: 767px) {
        white-space: nowrap;
        display: flex;
        width: fit-content;
        gap: 16px;
    }

    @media (min-width: 768px) {
        display: flex;
        gap: 40px;
        // flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        position: relative;
        // overflow: hidden;
        display: flex;
        flex-direction: column;

        > * {
            height: 100%;
        }

        @media (max-width: 767px) {
            width: 85vw;
            max-width: none;
            display: inline-flex;
            white-space: normal;
            align-self: stretch;
            &:last-child {
                margin-right: 16px;
            }
        }
        @media (min-width: 768px) {
            box-sizing: border-box;
        }
        @media (min-width: 1024px) {
            flex-basis: calc((1 / var(--subscription-count, 4)) * 100%);
        }
    }
}

.subscription {
    $subscription: &;
    position: relative;
    // border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    @media (min-width: 768px) {
        &:hover {
            // box-shadow: 0px 0px 5px var(--gray-60);
            cursor: pointer;

            #{$subscription}__primary-cta {
                --angle-translate: 1;
            }
            #{$subscription}__image {
                transform: translateY(-10px);
            }
        }
    }

    &__card {
        padding: 32px 16px 16px;
        background-color: white;
        flex: 1;
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            padding: 32px 24px 16px;
        }

        &__tophat {
            position: absolute;
            top: 14px;
            left: 0;
            padding: 6px 16px;
            font-size: em(12);
            transform: translate(0, -99%);
            background-color: white;
            z-index: 1;
            #{$subscription}--highlighted & {
                color: black;
                background-color: #00D63C;
                :global(.segment-business) & {
                    color: white;
                    background-color: black;
                }
            }
            // border-radius: 8px 8px 0 0;
            @media (min-width: 768px) {
                padding: 8px 24px;
            }
        }
    }

    &__title {
        margin-bottom: 4px;
        font-weight: 600;
    }

    &__price {
        // color: var(--gray-100);
        margin-bottom: 8px;

        &--strikethrough {
            display: inline-block;
            position: relative;
            color: var(--gray-100);
            font-weight: 400;
            line-height: 1;
            margin-right: 8px;

            :global(svg) {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                :global(.segment-business) & {
                    color: var(--primary-orange);
                }
            }
        }

        &__yearly-savings {
            display: inline;
            align-self: flex-start;
            // color: black;
            font-weight: 400;
            font-size: 12px;
            line-height: 1;
            // padding: 7px 10px;
            // border: 1px solid black;
            border-radius: 2px;
            margin-bottom: 16px;

            &--yearly-toggle {
                border-color: var(--gray-90);
                color: var(--gray-90);
            }
        }
    }

    &__catchphrase {
        font-size: 14px;
        // height: calc(1.6* 2em);
        margin-bottom: 24px;
        overflow: hidden;

        @media (min-width: 768px) {
            margin-bottom: 24px;
        }
    }
    &__bullets {
        color: var(--gray-100);
        margin-bottom: 40px;

        @media (max-width: 767px) {
            margin-bottom: 8px;
            font-size: 12px;
        }
    }

    &__image {
        width: 100%;
        margin-top: auto;
        transition: transform 0.5s;

        @media (max-width: 767px) {
            [class*='Image_image'],
            &__inner {
                max-height: 150px;
                margin: 0 auto;
            }
        }

        &__inner {
            width: 100%;
            transition: all 0.3s;
            --rotation: 0;
            --x: 0;

            &--alt {
                position: absolute;
                top: 0;
            }

            &--hover {
                @media (hover: hover) and (pointer: fine) {
                    transition-duration: 0.5s;
                    transform: translateX(var(--x)) rotate(var(--rotation));
                }
            }

            @media (hover: none) and (pointer: coarse) {
                transition-duration: 0.5s;
                transform: translateX(var(--x)) rotate(var(--rotation));
            }
        }
    }

    &__cta-group {
        margin-bottom: 24px;
    }
    &__primary-cta {
        @media (max-width: 767px) {
            padding: 6px 16px;
        }
    }

    :global(.segment-business) & {
        &__price {
            color: var(--primary-orange);
        }
        &__primary-cta {
            background-color: black;
            --hover-color: var(--primary-orange);
        }
    }

    &__description {
        padding: 1.5em 2em 1.5em;
        border-top: solid 1px var(--primary-white);

        @media (min-width: 768px) {
            flex: 1;
        }
    }

    &__explore a {
        text-align: center;
        padding: 1.25em 2em 1.25em;
        border-top: solid 1px var(--primary-white);
        display: flex;
        justify-content: center;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        margin: 24px;

        @media (min-width: 768px) {
            display: none;
        }
        &__indicator {
            width: 6px;
            height: 6px;
            border-radius: 100%;
            border: 1px solid black;
            margin: 0 12px;

            &--active {
                background-color: black;
            }
        }
    }
    &--highlighted {
        #{$subscription}__card {
            background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
            color: black;
        }
        #{$subscription}__price {
            color: black;
        }
        #{$subscription}__primary-cta {
            --hover-color: white;
            &:hover {
                --text-color: black;
            }
        }
        @media (hover: hover) {
            &:hover #{$subscription}__primary-cta {
                --text-color: black;
            }
        }
        #{$subscription}__bullets {
            color: black;
        }
        :global(.segment-private) & #{$subscription}__price--strikethrough {
            color: rgb(233, 233, 233);
            // opacity: 0.7;
            :global(svg) {
                color: white;
            }
        }

        :global(.segment-business) & #{$subscription}__card {
            background: black;
            color: white;

            #{$subscription}__price {
                color: var(--primary-orange);
            }
            #{$subscription}__primary-cta {
                background-color: var(--primary-orange);
                --hover-color: white;
            }
            @media (hover: hover) {
                &:hover #{$subscription}__primary-cta {
                    --text-color: black;
                }
            }

            #{$subscription}__bullets {
                color: white;
                ul li:before {
                    background: center / contain no-repeat
                        url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2175_2164)'%3E%3Ccircle cx='12' cy='12' r='12' fill='white'/%3E%3Cpath d='M18 6C18 6 15.3074 7.43478 13.1538 10.7826C11.0003 14.1304 11 16.5217 11 17' stroke='%23FF5B00' stroke-width='1.5'/%3E%3Cpath d='M7 12C7.33333 12 8.66667 12.9091 9.66667 14.2728C10.6667 15.6364 11 16.5455 11 17' stroke='%23FF5B00' stroke-width='1.5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2175_2164'%3E%3Crect width='24' height='24' fill='%23FF5B00'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}
