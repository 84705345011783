@use "sass:math";

.features-group-module {
    &__title {
        text-align: center;
        margin-bottom: 1em;
    }

    &__inner {
        position: relative;
    }

    &__sticky-wrapper {
        position: absolute;
        left:0;
        right:0;
        bottom: 0;
        height: 100%;
        z-index: 50;
        will-change: opacity;
        top:0;

        @media (max-width: 1100px) {
            display: none;
        }

        &__inner {
            position: sticky;
        }
    }

    &__feature-outer {
        width: (math.div(3, 12) * 100%);
        position: relative;

        &--left {
            margin-left: (math.div(1, 12) * 100%);
        }

        &--right {
            margin-left: auto;
            margin-right: (math.div(1, 12) * 100%);
        }

        &__list {
            position: absolute;
            left:0;
            width: 100%;

            &--top {
                bottom: 100%;
            }

            &--bottom {
                top: 100%;
                margin-top: 2em;
            }
        }
    }

    &__feature {
        &--inactive {
            color: var(--cloud);
            margin-bottom: 1em;
            animation-duration: .3s;
        }

        &--active {
            animation: featurein .4s both;
            margin-top: 1em;
            margin-bottom: 2em;
        }
    }
}

.feature {
    display: flex;
    padding-bottom: 18em;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: column;
        padding-bottom: math.div(100, 16) + em;
    }

    &__content {
        flex: 0 0 (math.div(3, 12) * 100%);
        visibility: hidden;

        @media (max-width: 1100px) {
            visibility: visible;
            flex-basis:  (math.div(5, 12) * 100%);;
        }

        @media (max-width: 800px) {
            max-width: 35em;
            margin-right: auto !important;
            margin-left: auto !important;
        }

        &--left {
            margin-left: (math.div(1, 12) * 100%);

            @media (max-width: 1100px) {
                margin-left: 0;
            }
        }

        &--right {
            order: 2;
            margin-left: (math.div(1, 12) * 100%);
        }

        &__title {
            margin-bottom: .5em;
        }
    }

    &__images {
        flex: 0 0 (math.div(5.60, 12) * 100%);
        position: relative;

        @media (max-width: 800px) {
            order: -1;
            width: 60%;
            margin-bottom: 3.5em;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        @media (max-width: 500px) {
            width: 100%;
        }

        &--left {
            order: -1;
            margin-left: (math.div(1, 12) * 100%);

            @media (max-width: 1100px) {
                margin-left: 0;
            }
        }

        &--right {
            margin-left: (math.div(1, 12) * 100%);
        }

        &__front {
            width: (math.div(2.5, 6) * 100%);
            z-index: 1;
            overflow: hidden;
            position: absolute;
            top: 25%;
            z-index: 2;
            animation: images-in .2s both;

            img {
                display: block;
                width: 100%;
            }

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }

        &__mid {
            margin-left: 0;
            margin-top: 15%;
            margin-right: auto;
            position: relative;
            width: (math.div(4, 6) * 100%);
            z-index: 1;
            animation: images-in .2s  both;

            &--left {
                margin-left: 0;
                margin-right: auto;
            }

            &--right {
                margin-left: auto;
                margin-right: 0;
            }
        }

        &__back {
            position: absolute;
            top: 0;
            width: (math.div(2.9, 6) * 100%);
            height: 40%;
            z-index: 0;
            display: flex;
            align-items: flex-end;
            animation: images-in .2s both;
            will-change: transform;

            &--left {
                left: (math.div(1, 6) * 100%);
            }

            &--right {
                right: (math.div(1, 6) * 100%);
            }
        }
    }
}


@keyframes featurein {
    from {
        opacity: 0;
        transform: translateX(-1em);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
