.statements-circle-module {
    height: var(--height);
    position: relative;

    &__inner {
        position: sticky;
        top:0;
        height: var(--screen-height);

        &__title {
            position: absolute;
            width: 95vw;
            text-align: center;
            left: 50%;
            top: 50%;
            z-index: 0;
            transform: translateY(-50%) translateX(-50%);
        }

        &__canvas {
            display: block;
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 1;
        }

        &__statements {
            position: absolute;
            width: 95vw;
            color: var(--primary-white);
            text-align: center;
            left: 50%;
            top: 50%;
            z-index: 2;
            transform: translateY(-50%) translateX(-50%);
        }
    }
}
