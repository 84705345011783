@use "sass:math";
.table__container {
    overflow-x: scroll;
}
.table {
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    font-size: 14px;
    line-height: 160%;
    position: relative;

    thead {
        border: 1px solid var(--gray-60);
        border-bottom: 1px solid var(--gray-60);
    }
    // Row and column header
    thead tr, tbody tr td:first-child {
        font-weight: 500;
        font-size: 14px;
    }
    td, th {
        padding: 20px;
        text-align: center;
    }
    tbody {
        tr:nth-child(odd) {
            background-color: var(--gray-30);
        }
        tr td:nth-child(1) {
            text-align: left;
        }
        td {
            border-right: 1px solid var(--gray-60);
            border-bottom: 1px solid var(--gray-60);

            &:first-child {
                border-left: 1px solid var(--gray-60);
            }
            &:last-child {
                // border-right: 0;
            }
        }
        // tr:last-child td, td:first-child {
        //     border-bottom: 0;
        // }
    }

    &__cell--highlighted {
        background-color: var(--accent);
        color: white;
    }
    &__cell--bold {
        font-weight: 500;
    }
}
