.module {
    scroll-margin-top: var(--header-height);

    &:first-child {
        margin-top: 0;
    }
    &:last-child:not(.module--collapse-margin-bottom) {
        margin-bottom: 0;
    }

    margin-top: var(--module-spacing);
    margin-bottom: var(--module-spacing);

    &--collapse-margin-top {
        margin-top: calc(-1 * var(--module-spacing));
    }
    &--collapse-margin-bottom {
        margin-bottom: calc(-1 * var(--module-spacing));
    }
}
