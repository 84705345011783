@use "sass:math";

.statements-module {
    position: relative;
    height: max(calc( var(--height)), 90vh);

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        height: max(calc( var(--height) * 1.5), 90vh);
    }

    &__title-wrapper {
        display: block;

        &--top {
            position: absolute;
            z-index: 2;
            left:0;
            top:0;
            right:0;
            bottom: 0;

            @media (max-width: 900px) {
                display: none;
            }
        }

        &--bottom {
            position: static;
            flex: 1;

            @media (min-width: 901px) {
                display: none;
            }
        }
    }

    &__title {
        position: sticky;
        left:  0;
        overflow: visible;
        box-sizing: border-box;

        &__inner {
            &__element {
                width: (math.div(5, 12) * 100%);
                transition: all .2s;

                &--right {
                    margin-left: (math.div(6.25, 12) * 100%);

                    @media (max-width: 1250px){
                        margin-left: (math.div(5.25,12) * 100%);
                        width: (math.div(6.75, 12) * 100%);
                    }

                    @media (max-width: 900px) {
                        margin-left: 0;
                        width: auto;
                    }

                    @media (max-width: 500px) {
                        margin-left: (math.div(0, 12) * 100%);
                        width: auto;
                    }

                }

                &--left {
                    margin-left: (math.div(1,12) * 100%);

                    @media (max-width: 1250px){
                        margin-left: 0;
                        width: (math.div(7,12) * 100%);
                    }

                    @media (max-width: 900px) {
                        width: auto;
                    }

                    @media (max-width: 600px) {
                        width: (math.div(9,12) * 100%);
                    }

                    @media (max-width: 500px) {
                        width: auto;
                    }
                }
            }

            @media (max-width: 900px) {
                font-size: 1.1em;
            }

            @media (max-width: 600px) {
                font-size: .8em;
            }
        }
    }

    &__background {
        height: 100%;

        @media (max-width: 900px) {
            height: auto;
            margin-bottom: 2em;
        }

        &__inner {
            display: flex;
            align-items: center;
            height: 100%;

            @media (max-width: 900px) {
                align-items: flex-start;
                justify-content: center !important;
            }

            &--left {
                justify-content: flex-end;
            }

            &--right {
                justify-content: flex-start;
            }
        }

        &__image {
            position: relative;
            z-index: 1;
            display: block;
            width: (math.div(4, 12) * 100%);

            @media (max-width: 950px){
                width: (math.div(6,12) * 100%);
            }

            @media (max-width: 650px){
                width: (math.div(8,12) * 100%);
            }

            @media (max-width: 550px){
                width: 90%;
            }

            &--left {
                margin-left: (math.div(1, 12) * 100%);

                @media (max-width: 1250px){
                    margin-left: 0;
                }

                @media (max-width: 800px){
                    margin-left: -1.5em;
                }

                @media (max-width: 550px){
                    margin-left: 0;
                }
            }

            &--right {
                order: 1;
                margin-right: (math.div(1, 12) * 100%);
                transform-origin: 100% 0%;

                @media (max-width: 1250px){
                    margin-right: 0;
                }

                @media (max-width: 800px){
                    margin-right: -1.5em;
                }

                @media (max-width: 550px){
                    margin-right: 0;
                }
            }
        }

        &__shape {
            padding-bottom:  (math.div(3, 12) * 100%);
            position: relative;
            width: (math.div(3, 12) * 100%);
            will-change: transform;

            @media (max-width: 750px){
                width: (math.div(6, 12) * 100%);
            }

            &--left {
                margin-right: (math.div(-2,12) * 100%);

                @media (max-width: 1250px) {
                    margin-right: (math.div(-2, 12) * 100%);
                }

                @media (max-width: 550px){
                    margin-right: (math.div(-5, 12) * 100%);
                }
            }

            &--right {
                order: 2;
                margin-left: (math.div(-2,12) * 100%);

                @media (max-width: 1250px) {
                    margin-left: (math.div(-2, 12) * 100%);
                }

                @media (max-width: 550px){
                    margin-left: (math.div(-5, 12) * 100%);
                }
            }

            &__angle {
                // nothing
            }
        }
    }
}
