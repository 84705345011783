@import '/assets/styles/utils.scss';

.paragraph-50 {
    font-style: normal;
    font-weight: normal;
    font-size: em(12);
    line-height: 160%;
}

.paragraph-100 {
    font-style: normal;
    font-weight: normal;
    font-size: 0.874em;
    line-height: 160%;
}

.paragraph-200 {
    font-style: normal;
    font-weight: normal;
    font-size: em(16);
    line-height: 160%;
}

.paragraph-300 {
    font-style: normal;
    font-weight: normal;
    font-size: em(20);
    line-height: 160%;
}
.paragraph-400 {
    font-style: normal;
    font-weight: normal;
    font-size: em(22);
    line-height: 160%;
}

.paragraph-600 {
    font-style: normal;
    font-weight: normal;
    font-size: em(24);
    line-height: 160%;

    @media only screen and (min-width: 768px) {
        font-size: em(26);
    }

    @media only screen and (min-width: 1024px) {
        font-size: em(28);
    }
}

.label-50 {
    font-style: normal;
    font-weight: 500;
    font-size: em(12);
    line-height: 160%;
}

.label-100 {
    font-style: normal;
    font-weight: 500;
    font-size: em(14);
    line-height: 160%;
}

.label-200 {
    font-style: normal;
    font-weight: 500;
    font-size: em(14);
    line-height: 160%;
    @media (min-width: 768px) {
        font-size: em(16);
    }
}

.label-300 {
    font-style: normal;
    font-weight: 500;
    font-size: em(16);
    line-height: 160%;
    @media (min-width: 768px) {
        font-size: em(18);
    }
}

.byline-50 {
    font-style: normal;
    font-weight: 500;
    font-size: em(12);
    line-height: em(16, 12);
    text-transform: uppercase;
}

.heading-50 {
    font-style: normal;
    font-weight: 500;
    font-size: em(16);
    line-height: 160%;

    @media only screen and (min-width: 768px) {
        line-height: 150%;
        font-size: em(20);
    }

    @media only screen and (min-width: 1024px) {
        // font-size: em(24);
    }
}

.heading-100 {
    font-style: normal;
    font-weight: 600;
    font-size: em(24);
    line-height: 120%;

    @media only screen and (min-width: 768px) {
        line-height: 120%;
        font-size: em(26);
    }

    @media only screen and (min-width: 1024px) {
        font-size: em(28);
    }
}

.heading-200 {
    font-style: normal;
    font-weight: 600;
    font-size: em(32);
    line-height: 120%;

    @media only screen and (min-width: 768px) {
        font-size: em(36);
    }

    @media only screen and (min-width: 1024px) {
        font-size: em(36);
    }
}

.heading-300 {
    font-style: normal;
    font-weight: 600;
    font-size: em(36);
    line-height: 120%;

    @media only screen and (min-width: 768px) {
        font-size: em(46);
    }

    @media only screen and (min-width: 1200px) {
        font-size: em(54);
    }
    @media only screen and (min-width: 1480px) {
        font-size: em(62);
    }
}

.heading-400,
.heading-500 {
    font-style: normal;
    font-weight: 600;
    font-size: em(40);
    line-height: 110%;

    @media only screen and (min-width: 768px) {
        font-size: em(44);
    }

    @media only screen and (min-width: 1024px) {
        font-size: em(60);
    }
    @media only screen and (min-width: 1248px) {
        font-size: em(80);
    }
}

.light-font-weight {
    font-weight: 400;
}
.heading-50,
.heading-100,
.heading-200,
.label-100 {
    &.light-font-weight,
    .light-font-weight {
        font-weight: 400;
    }
}
.heading-300,
.heading-400,
.heading-500 {
    &.light-font-weight,
    .light-font-weight {
        font-weight: 300;
    }
}
.balance-text {
    text-wrap: balance;
    * {
        text-wrap: balance;
    }
}

.text-center {
    text-align: center;
}
